import "./main.scss";

const APP_CONFIGURATION = {};

namespace WebApplication {
  export function init() {
    $(".person-container .image-and-content .button-toggle").on(
      "click",
      (event) => {
        const iac = $(event.target).closest(".image-and-content");

        const button = iac.find(".button-toggle");
        const content = iac.find(".content");
        const isExpanded = content.attr("data-expanded");

        if (isExpanded) {
          content.removeAttr("data-expanded");
          button.removeAttr("data-expanded");
        } else {
          content.attr("data-expanded", "true");
          button.attr("data-expanded", "true");
        }
      }
    );
  }
}

jQuery(function () {
  WebApplication.init();
});
